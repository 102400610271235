import { React, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Landing from '../pages/Landing';
import Products from '../pages/Products';
import CaseStudies from '../pages/CaseStudies';
import Pricing from '../pages/Pricing';
import Footer from './Footer';
import "../styles/main.css";
import About from '../pages/About';
import PrivacyPolicy from '../pages/PrivacyPolicy';

export default function App() {
  const [navMode, setNavMode] = useState("");

  const toggleNavMode = (mode) => {
    if (mode) {
      setNavMode("nav-white")
    } else {
      setNavMode("nav-black")
    }
  }
  
  return (
    <>
      <BrowserRouter>
        <Header nav={navMode} />
        <Routes>
          <Route path='/' element={<Landing toggleNav={toggleNavMode} />} />
          <Route path='/product/mobile-app/' element={<Products product='mobile-app' setNav={toggleNavMode} />} />
          <Route path='/product/email-newsletter/' element={<Products product='email-newsletter' setNav={toggleNavMode} />} />
          <Route path='/product/website/' element={<Products product='website' setNav={toggleNavMode} />} />
          <Route path='/case-studies/' element={<CaseStudies setNav={toggleNavMode} />} />
          <Route path='/pricing/' element={<Pricing setNav={toggleNavMode} />} />
          <Route path='/about/' element={<About setNav={toggleNavMode} />} />
          <Route path='/privacypolicy/' element={<PrivacyPolicy setNav={toggleNavMode}/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}
