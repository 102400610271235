import { React, useState, useRef, useEffect } from "react";

export default function About(props) {
  useEffect(() => {
    document.title = "About Us | NoCodeApp";
    props.setNav(false);
  });

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [sendStatus, setSendStatus] = useState("not-submitted");
  const formRef = useRef();

  const sendMessage = (event) => {
    event.preventDefault();
    if (name && email && message) {
      console.log(name, email, message);
      setSendStatus("submitted");
      fetch(
        "https://w8xrwwelf7.execute-api.us-east-1.amazonaws.com/prod/submit",
        {
          method: "POST",
          body: JSON.stringify({
            name: name,
            email: email,
            message: message,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res)
        .then((response) => {
          setSendStatus("sent");
          formRef.current.reset();
        })
        .catch((err) => {
          setSendStatus("error");
          formRef.current.reset();
        });
    }
  };

  return (
    <section id="about" className="container">
      <h1 className="font-30 page-main-title">About</h1>
      <div className="custom-container">
        <p className="description font-16">
          NoCodeApp Team primarily comprises of fintech veterans, who have been
          building trading systems for the global financial industry for
          decades, Fintech Global Center. The core team, along with several
          innovative young tech professionals, took on the challenge of building
          a "true" no code application platform for building mobile
          applications, primarily for publishing content, free or permissioned,
          in an engaging way and a very effective communication app solving
          several business challenges. List of features is big, the
          applicability is wide, and the most important thing is, this we
          believe is a true 9-99 age group no code platform allowing one to
          build, run and manage their app and contents using simply a phone or a
          tablet (or a laptop/desktop). The business and data intelligence the
          platform provides on the users and the usage of your application, is
          equivalent to what financial professionals are accustomed of. We hope
          you enjoy using it as much as we have enjoyed building it!
        </p>
      </div>

      <div className="contact-section">
        <div className="contact-cover">
          <p className="wg-6 font-30" style={{ color: "white" }}>
            Get in touch with us for
            <br /> more information
          </p>
        </div>
        <form
          className="contact-form"
          ref={formRef}
          onSubmit={(e) => {
            sendMessage(e);
          }}
        >
          <input
            className="nca-textfield"
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
            placeholder="Your Name"
          />
          <input
            className="nca-textfield"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            placeholder="Your Email"
          />
          <textarea
            className="nca-textfield"
            required
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            placeholder="You Message"
            cols="30"
            rows="6"
          ></textarea>
          {sendStatus === "not-submitted" ? (
            <button className="btn-primary" type="submit">
              Submit
            </button>
          ) : sendStatus === "submitted" ? (
            <p>Sending Message...</p>
          ) : sendStatus === "sent" ? (
            <p style={{ color: "#00A66A" }}>
              Your message has been sent!
              <br /> Our team shall reach out to you soon
            </p>
          ) : sendStatus === "error" ? (
            <p style={{ color: "#FA4339" }}>
              Oops, Something went wrong. Please try again later
            </p>
          ) : null}
        </form>
      </div>
    </section>
  );
}
