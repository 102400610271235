import { React, useEffect, useState } from 'react';
import { productsData } from "../page-data";

export default function Products(props) {
  const [products, setProducts] = useState([]);
  const [features, setFeatures] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    document.title = `${props.product === "mobile-app" ? "Mobile App" : props.product === "email-newsletter" ? "Email & Newsletter" : "Website"} | NoCodeApp`;
    props.setNav(false);
    setProducts(productsData[props.product].allProducts);
    setFeatures(productsData[props.product].features);
    setTitle(productsData[props.product].title);
  })

  return (
    <section id="products-container">
      <section className="product-title">
        <h1 className="title font-30 page-main-title">{title}</h1>
        {props.product === 'mobile-app' ? <p className="desc font-16">Mobile Phones and Apps have become ubiquitous in daily lives. People spend more time on
          mobile
          devices for group communication than on laptops. Mobile devices are lot more accessible than laptops. People
          carry
          mobile devices all the time and any gap in their schedule becomes a target to get their attention.Notifications
          for the apps are a great way to get a users attention.

          <br /> Use Nocodeapp App to get users attention better using Mobile App and App notifications. Messages are
          formatted specifically for the mobile screen for a better engagement. Your app will be ready in minutes, we do
          everything including managing the apps in the app stores. You pay only for the messages you send, above the free
          tier.
        </p> : props.product === 'email-newsletter' ? <p className="desc font-16">Emails have been the most effective way to communicate a message. Hundreds of billions of emails are sent daily still. The key challenges with emails for group communication are that they get buried in important emails, are hard to find, are not very engaging most of the times, can not grab a users attention in just a few seconds, end up in junk/spam  etc. These all result in a low click rate.
          Use Nocodeapp Email to create and send engaging emails that attract user attention. Increase your click rate. Send tens or hundreds of thousands of email in a few minutes, not in tens of  minutes or hours. No monthly fix price. Pay for only the messages you send above the free tier. Chimps delivered mails. Use Nocodeapp Email now and see the difference.

          <br />Newsletters are a great tool that bring a lot of value to the receiver and sender both. Some of the biggest challenges with newsletter are, putting the information together to be sent, formatting them, arranging the information in an engaging way and sending it out in a timely manner. This sometimes takes days, weeks and even months.
          Nocodeapp Newsletter leverages the messages you send already as above, and helps put an engaging newsletter quickly in minutes. While one can create categories and create multiple newletters to be sent out to different users, Nocodeapp’s unique technology creates custom newsletters for each user based on their interest automatically.You just select the messages you want to send and the users you want to send to, and the system will automatically create a custom newsletter for each user. Unique, right? Get your newsletters out in minutes, not days and weeks.</p>
          : <p className='desc font-16'>When was the last time your website was updated? Keeping websites updated and consistent with group communication is hard. Latest information are hidden in a small box somewhere or on a page that is hard to navigate to from the homepage.

            <br />Nocodeapp Web takes your communication and turns it into a live social media like feed on your website. The messages can be general which can be seen by anyone or custom to a sub-group or individuals who can see the messages intended for them by logging in. After a free tier, you pay only for the messages you create to be posted on the website, nothing else, no fixed cost of any kind. Modernize your website and have your private social media like feed being updated on your website as soon as you have a message in seconds.</p>}
        <div className="product-features">
          {features.map((feature) => (
            <div className="feature">
              <img src={feature.icon} alt="" />
              <p className="font-14 wg-6">{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>
      <div className="product-full">
        {products.map((product) => (
          <div className="product">
            {product.title ? <h1 className="pd-title font-24">{product.title}</h1> : null}
            {product.desc ? <p className="pd-desc font-18">{product.desc}</p> : null}
            <img src={product.image} className="pd-img" />
          </div>
        ))}
      </div>
    </section>
  )
}
