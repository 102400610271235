import { React, useState, useEffect } from 'react'
import { caseStudies } from "../page-data";

export default function CaseDetails(props) {
  const allCases = caseStudies;
  const [selectedCase, setCase] = useState({});

  useEffect(() => {
    setCase(allCases[props.caseId]);
  })

  return (
    <section className="case-details-wrapper">
      <div className="case-detail">
        <span class="material-symbols-rounded closeIcon" onClick={props.closeTab}>
          close
        </span>
        <div className="case-img">
          <img src={selectedCase.image} alt="" />
        </div>
        <div className="case-description">
          <div className="top-text">
            <h1>{selectedCase.title}</h1>
            <p>{selectedCase.desc}</p>
          </div>
          <a href="https://my.nocodeapp.center" target="_blank">Get Started <span class="material-symbols-rounded">
            arrow_forward
          </span></a>
        </div>
        <ul className="case-whyList caseList">
          <h2>Why</h2>
          {selectedCase.whyList ? selectedCase["whyList"].map((whyItem) => (
            <li><span className='tick'></span>{whyItem}</li>
          )) : null}
        </ul>
        <ul className="case-howList caseList">
          <h2>How</h2>
          {selectedCase.howList ? selectedCase["howList"].map((howItem) => (
            <li className='tick-top'><span className='tick'></span>{howItem}</li>
          )) : null}
        </ul>
      </div>
    </section>
  )
}
