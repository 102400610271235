import { React, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Logo } from './Icons';

export default function Header(props) {
  const [subMenuState, setSubMenuState] = useState(false);
  const navRef = useRef();

  const toggleSubmenu = () => {
    setSubMenuState(!subMenuState);
  }

  const toggleNav = () => {
    navRef.current.classList.toggle("open-nav");
  }

  return (
    <header className={`navigation ${props.nav}`}>
      <div id="logo">
        <Link to='/'>
          {props.nav === "nav-white" ? <Logo type="logo-white" size={{ width: 60, height: 70 }} /> : props.nav === "nav-black" ? <Logo type="logo-pink" size={{ width: 60, height: 70 }} /> : null}
        </Link>
      </div>
      <div className="navigation-wrapper" ref={navRef}>
        <div className="nav-top">
          <div className="mob-nav-logo">
            <Logo type="logo-compact" size={{ widht: 40, height: 40 }} />
            <span className="material-symbols-rounded close-nav-ic" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>
              close
            </span>
          </div>
          <ul className="routes main-head">
            <li className="font-14 main-links"><Link to="/" className='nav-links' onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Home</Link></li>
            <li className="font-14 main-links submenu-enabled" onClick={toggleSubmenu}>
              <p className='nav-links'>Products <span className="faq-arrow material-symbols-rounded">
                expand_more
              </span></p>
              {subMenuState ? <ul className="submenu">
                <li className="font-12"><Link to="/product/mobile-app/" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Mobile App</Link></li>
                <li className="font-12"><Link to="/product/email-newsletter/" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }} >Email & Newsletter</Link></li>
                <li className="font-12"><Link to="/product/website/" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Website</Link></li>
              </ul> : <></>}
            </li>
            <li className="font-14 main-links"><Link to="/case-studies/" className='nav-links' onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Case Studies</Link></li>
            <li className="font-14 main-links"><Link to="/pricing/" className="nav-links" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Pricing</Link></li>
            <li className="font-14 main-links"><Link to="/about/" className="nav-links" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>About Us</Link></li>
          </ul>
        </div>
        <div className="nav-bottom">
          <button className="btn-primary"><a href="https://my.nocodeapp.center" target="_blank">Log In</a></button>
          <a className="help_link"><Link to="/about/" onClick={() => { toggleNav(); if (subMenuState) { toggleSubmenu() } }}>Help</Link></a>
        </div>
      </div>
      <div className="nav-ham" onClick={toggleNav} id={props.nav}>
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.1998 12H4.7998" stroke="white" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
          <path d="M19.1998 6.85693H4.7998" stroke="white" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
          <path d="M19.1998 17.1428H4.7998" stroke="white" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
        </svg>
      </div>
    </header>
  )
}
