import { React, useEffect, useState } from "react";
import { pricingPlans } from "../page-data";
import { PricingPageIcons } from "../components/Icons";
import Faqs from "../components/Faqs";

export default function Pricing(props) {
  const [activeTab, setActiveTab] = useState("Mobile");

  const [currency, setCurrency] = useState("USD");
  const [selected, setSelected] = useState("Mobile");

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  // Cost Calculator
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [noOfPosts, setNoOfPosts] = useState(0);
  const [userCost, setUserCost] = useState(0);
  const [postCost, setPostCost] = useState(0);

  function handleCalculateClick() {
    setUserCost(noOfUsers * 0.5);
    setPostCost(noOfPosts * 0.1);
    setEstimatedCost(userCost + postCost);
    setIsPopupOpen(true);
  }

  function handleNoOfUsersChange(e) {
    setNoOfUsers(e.target.value);
  }

  function handleNoOfPostsChange(e) {
    setNoOfPosts(e.target.value);
  }

  const [showDiv2, setShowDiv2] = useState(false);

  const [isMobileVisible, setIsMobileVisible] = useState(true);
  const [isEmailVisible, setIsEmailVisible] = useState(true);

  useEffect(() => {
    document.title = "Pricing | NoCodeApp";
    props.setNav(false);
  });

  return (
    <>
      <section id="pricing" className="container">
        <div className="pageTitle">
          <h1 className="font-30 page-main-title">Pay as you go!</h1>
        </div>

        <p className="description font-16">
          Forget about unnecessary charges for features you never end up using.
        </p>
        <div className="pricing-border">
          <p className="description font-16 pricing-desc">
            With us, you -only- pay for what you use!
          </p>
        </div>

        {showDiv2 ? (
          <div id="div1">
            <div className="pricing pricing-border-darker pad-top-3">
              <div className="responsive-titles">
                <div className="titles-left-align">
                  <a onClick={() => setShowDiv2(false)}>
                    <PricingPageIcons
                      type="back"
                      size={{ width: 36, height: 36 }}
                    />
                  </a>
                  <span class="font-18 font-light-bold">
                    Calculate Estimate Cost
                  </span>
                </div>
                <div className="right-align select-wrapper">
                  <label htmlFor="currency">
                    Select your preferred currency
                  </label>
                  <select value={currency} onChange={handleCurrencyChange}>
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className="breakdown">
                <div className={isMobileVisible ? "" : "hidden"}>
                  <div className="">
                    <div className="breakdown-subtitle">
                      <h4 className="font-18 wg-6">Mobile App</h4>
                    </div>
                    <div className="breakdown-sliders">
                      <div className="breakdown-slider">
                        <label>Add No. of Users</label>
                        <input
                          type="range"
                          className="input-secondary input-slider"
                          min="0"
                          max="1000000"
                          step="100"
                          value={noOfUsers}
                          onChange={handleNoOfUsersChange}
                        />
                        <input
                          type="text"
                          className="input-secondary"
                          value={noOfUsers}
                          onChange={handleNoOfUsersChange}
                        />
                      </div>
                      <br />
                      <div className="breakdown-slider">
                        <label>Monthly Posts</label>
                        <input
                          type="range"
                          className="input-secondary input-slider"
                          min="0"
                          max="1000000"
                          step="100"
                          value={noOfPosts}
                          onChange={handleNoOfPostsChange}
                        />
                        <input
                          type="text"
                          className="input-secondary"
                          value={noOfPosts}
                          onChange={handleNoOfPostsChange}
                        />
                      </div>
                    </div>
                    {/* {estimatedCost > 0 && ( */}
                    <table>
                      <thead>
                        <tr>
                          <th>App Messages</th>
                          <th>Cost Per Message ({currency})</th>
                          <th>Total Cost ({currency})</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Users</td>
                          {/* <td><span className='text-left'>*</span>{userCost}<span className='text-right'>=</span></td> */}
                          <td>{userCost}</td>
                          <td>{estimatedCost}</td>
                        </tr>
                        <tr>
                          <td>Posts</td>
                          <td>{postCost}</td>
                          <td>{estimatedCost}</td>
                        </tr>
                        {/* <tr>
                              <td>Total</td>
                              <td>{estimatedCost}</td>
                              <td>{estimatedCost}</td>
                              </tr> */}
                      </tbody>
                    </table>
                    {/* )} */}
                    <button onClick={handleCalculateClick}>Calculate</button>
                  </div>
                </div>
                <button onClick={() => setIsMobileVisible(!isMobileVisible)}>
                  Toggle
                </button>
              </div>
              <div className="breakdown">
                <h2>Email</h2>
                <div className={isEmailVisible ? "" : "hidden"}>
                  <p>Cost 1: $20</p>
                  <p>Cost 2: $10</p>
                  <p>Total: $30</p>
                </div>
                <button onClick={() => setIsEmailVisible(!isEmailVisible)}>
                  Toggle
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div id="div2">
            <div className="pricing pricing-border-darker pad-top-3">
              <div className="responsive-titles">
                <div className="titles-left-align">
                  <span class="font-18 font-light-bold">Our Products</span>
                  {/* <u id="pricing-link" class='font-14'  onClick={() => setShowDiv2(true)}>Calculate Estimate Cost</u> */}
                </div>
                <div className="right-align select-wrapper">
                  <label htmlFor="currency">
                    Select your preferred currency
                  </label>
                  <select value={currency} onChange={handleCurrencyChange}>
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="pricing">
              <div class="wrapper">
                <article>
                  {activeTab === "Mobile" && selected && (
                    <div className="pricing-table-container">
                      <table className="vertical-table">
                        <tbody>
                          <tr>
                            <td colspan="2">14 Day Free Trial</td>
                          </tr>
                          <tr>
                            <th>{pricingPlans[currency][selected].subTitle}</th>
                            <td>
                              {pricingPlans[currency][selected].Price6}/month
                            </td>
                          </tr>
                          <tr>
                            <th>Android or iOS App</th>
                            <td>
                              {pricingPlans[currency][selected].Price7}/month
                            </td>
                          </tr>
                          <tr>
                            <th>Both Android & iOS Apps</th>
                            <td>
                              {pricingPlans[currency][selected].Price8}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                  )}

                  {activeTab === "Email" && selected && (
                    <div className="pricing-table-container">
                      <table className="vertical-table">
                        <tbody>
                          <tr>
                            <td colspan="2">14 Day Free Trial</td>
                          </tr>
                          <tr>
                            <th>{pricingPlans[currency][selected].subTitle}</th>
                            <td>
                              {pricingPlans[currency][selected].Price6}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table className="vertical-table">
                        <tbody>
                          <tr>
                            <th>First 1000 Users</th>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <th>Every Additional 1000 Users</th>
                            <td>
                              {pricingPlans[currency][selected].Price7}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                  )}

                  {(activeTab === "Mobile" || activeTab === "Email") &&
                    selected && (
                      <div className="pricing-table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>{pricingPlans[currency][selected].Title}</th>
                              <th>
                                {pricingPlans[currency][selected].CostTitle}
                                <br />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Up to 1,000</td>
                              <td>Free</td>
                            </tr>
                            <tr>
                              <td>Next 10,000</td>
                              <td>{pricingPlans[currency][selected].Price1}</td>
                            </tr>
                            <tr>
                              <td>Next 100,000</td>
                              <td>{pricingPlans[currency][selected].Price2}</td>
                            </tr>
                            <tr>
                              <td>Next 400,000</td>
                              <td>{pricingPlans[currency][selected].Price3}</td>
                            </tr>
                            <tr>
                              <td>Next 500,000</td>
                              <td>{pricingPlans[currency][selected].Price4}</td>
                            </tr>
                            <tr>
                              <td>More</td>
                              <td>{pricingPlans[currency][selected].Price5}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                  {activeTab === "Website" && selected && (
                    <div className="pricing-table-container">
                      <table className="vertical-table">
                        <tbody>
                          <tr>
                            <td colspan="2">14 Day Free Trial</td>
                          </tr>
                          <tr>
                            <th>Generic Plan</th>
                            <td>
                              {pricingPlans[currency][selected].Price3}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table>
                        <thead>
                          <tr>
                            <th>Posts</th>
                            <th>
                              Cost Per Post
                              <br />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Up to 5</td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Next 100</td>
                            <td>
                              {pricingPlans[currency][selected].Price1}/month
                            </td>
                          </tr>
                          <tr>
                            <td>More</td>
                            <td>
                              {pricingPlans[currency][selected].Price2}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  {activeTab === "Social" && selected && (
                    <div className="pricing-table-container">
                      <table className="vertical-table">
                        <tbody>
                          <tr>
                            <td colspan="2">14 Day Free Trial</td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table>
                        <thead>
                          <tr>
                            <th>Posts Per Handle Per Month</th>
                            <th>
                              Cost Per Handle
                              <br />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Up to 100</td>
                            <td>
                              {pricingPlans[currency][selected].Price1}/month
                            </td>
                          </tr>
                          <tr>
                            <td>More</td>
                            <td>
                              {pricingPlans[currency][selected].Price2}/month
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </article>
                <aside>
                  <div className="menu-container">
                    <div
                      className={`menu-option icon-title ${
                        activeTab === "Mobile" ? "active" : ""
                      }`}
                      onClick={() =>
                        setSelected("Mobile") & setActiveTab("Mobile")
                      }
                    >
                      <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20,400,0,0"
                      />
                      <span class="material-symbols-outlined">
                        arrow_circle_right
                      </span>
                      Mobile
                    </div>
                    <div
                      className={`menu-option icon-title ${
                        activeTab === "Email" ? "active" : ""
                      }`}
                      onClick={() =>
                        setSelected("Email") & setActiveTab("Email")
                      }
                    >
                      <span class="material-symbols-outlined">
                        arrow_circle_right
                      </span>
                      Email
                    </div>
                    <div
                      className={`menu-option icon-title ${
                        activeTab === "Website" ? "active" : ""
                      }`}
                      onClick={() =>
                        setSelected("Website") & setActiveTab("Website")
                      }
                    >
                      <span class="material-symbols-outlined">
                        arrow_circle_right
                      </span>
                      Website
                    </div>
                    <div
                      className={`menu-option icon-title ${
                        activeTab === "Social" ? "active" : ""
                      }`}
                      onClick={() =>
                        setSelected("Social") & setActiveTab("Social")
                      }
                    >
                      <span class="material-symbols-outlined">
                        arrow_circle_right
                      </span>
                      Social Media
                      {/* <div className="social-media-icons">
                        <PricingPageIcons type="linkedin" size={{ width: 35, height: 35 }} />
                        <PricingPageIcons type="facebook" size={{ width: 35, height: 35 }} />
                        <PricingPageIcons type="instagram" size={{ width: 35, height: 35 }} />
                        <PricingPageIcons type="twitter" size={{ width: 35, height: 35 }} />
                      </div> */}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        )}
      </section>
      <Faqs />
    </>
  );
}
