import React from 'react';
import { Logo } from './Icons';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="nca-footer">
      <form action="https://my.nocodeapp.center/dashboard" method='GET' className="get-started-card">
        <p className="font-16 gsff-title" style={{ color: "white" }}>Get Started for Free</p>
        <div className="controls">
          <input type="text" className="input-primary" placeholder="Your email" name='userEmail'
            pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' />
          <button className="start-free">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.1667" fill="#CF3560" stroke="white" strokeWidth="1.66667" />
              <path d="M14.167 20H25.8337" stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round" />
              <path d="M20 14.1667L25.8333 20.0001L20 25.8334" stroke="white" strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </form>
      <div className="footer-head">
        <Logo type="logo-white" size={{ width: 40, height: 60 }} />
        <a href="#">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.8685" cy="15.4413" r="15.3034" transform="rotate(90 15.8685 15.4413)"
              fill="#48484A" />
            <path d="M15.8799 11.4714L15.8799 20.5401" stroke="white" strokeWidth="1.20798"
              strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M19.2812 14.8738L16.1127 11.0184C16.0837 10.9831 16.048 10.9549 16.008 10.9356C15.968 10.9162 15.9247 10.9062 15.8808 10.9062C15.8369 10.9062 15.7936 10.9162 15.7536 10.9356C15.7136 10.9549 15.6779 10.9831 15.6489 11.0184L12.4797 14.8738"
              stroke="white" strokeWidth="1.20798" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
      </div>
      <div className="footer-body">
        <div className="footer-links">
          <Link to="/">Home</Link><Link to="/product/mobile-app/">Products</Link><Link to="/case-studies/">Case Studies</Link><Link to="/pricing/">Pricing</Link>
          <Link to="/about/">About Us</Link>
        </div>
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/no-code-app-center/about" id='linkedin-link'><img src={require("../assets/images/linkedin.png")} alt="" />
            <p className="social-name">Linkedin</p>
          </a><a href="https://www.facebook.com/nocodeappcenter" id='fb-link'><img src={require("../assets/images/facebook.png")} alt="" />
            <p className="social-name">Facebook</p>
          </a><a href="https://www.instagram.com/nocodeapp.center" id='insta-link'><img src={require("../assets/images/instagram.png")} alt="" />
            <p className="social-name">Instagram</p>
          </a><a href="https://twitter.com/NoCodeAppCenter" id='twitter-link'><img src={require("../assets/images/twitter.png")} alt="" />
            <p className="social-name">Twitter</p>
          </a>
        </div>
        <div className="footer-contact">
          <p className="address1">
            101 Crawfords Corner Rd, 4116 Holmdel, NJ 07733 USA
          </p>
          <p className="address2">Infocity IT Tower 2 #104, Gandhinagar, India</p>
          <a href="Tel: +1-646-688-7500" id='phone-link' className='footer-link'><p className="call">+1-646-688-7500</p></a>
          <a href="mailto:info@nocodeapp.center" id='email-to-link' className='footer-link'><p className="mail">info@nocodeapp.center</p></a>
        </div>
        <div className="footer-trademark">
          <p>© 2020-2021 Nocodeapp Center LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}
