import { React, useState, useEffect } from 'react';
import { CaseStudyIcon } from '../components/Icons';
import CaseDetails from './CaseDetails';

export default function CaseStudies(props) {
  useEffect(() => {
    document.title = "Case Studies | NoCodeApp";
    props.setNav(false);
  })

  const [popupState, changePopupState] = useState({ open: false, id: null });
  const openDetailsTab = (caseId) => {
    changePopupState({ open: true, id: caseId - 1 });
  }
  const closeDetailsTab = () => {
    changePopupState({ open: false, id: null });
  }

  return (
    <>
      {popupState.open ? <CaseDetails caseId={popupState.id} closeTab={closeDetailsTab} /> : null}
      <section id="case-studies" className='container'>
        <div className="pageTitle">
          <h1 className='font-30 page-main-title'>Case Studies</h1>
        </div>
        <div className="case-studies">
          <div className="case-study" onClick={() => {openDetailsTab(1)}}>
            <div className="icon">
              <CaseStudyIcon id={1} />
            </div>
            <div className="brief">
              <h2 className="case-study__title">Newsletter</h2>
              <p className="case-study__desc">Build an interactive & easy to use newsletter app to deliver content to your users in real-time.</p>
            </div>
          </div>
          <div className="case-study" onClick={() => {openDetailsTab(2)}}>
            <div className="icon">
              <CaseStudyIcon id={2} />
            </div>
            <div className="brief">
              <h2 className="case-study__title">School</h2>
              <p className="case-study__desc">Build a communication app for students, parents & teachers to stay connected for important updates.</p>
            </div>
          </div>
          <div className="case-study" onClick={() => {openDetailsTab(3)}}>
            <div className="icon">
              <CaseStudyIcon id={3} />
            </div>
            <div className="brief">
              <h2 className="case-study__title">Event</h2>
              <p className="case-study__desc">Build a feature-rich app for your events to keep your audience engaged and updated.</p>
            </div>
          </div>
          <div className="case-study" onClick={() => {openDetailsTab(4)}}>
            <div className="icon">
              <CaseStudyIcon id={4} />
            </div>
            <div className="brief">
              <h2 className="case-study__title">Catalog</h2>
              <p className="case-study__desc">Build an easy to browse digital catalogue of your products for your customers.</p>
            </div>
          </div>
        </div>
        <div className="bottom-text">
          <p className='font-18 wg-5'>If you’re still not sure, send us a copy of your newsletter and we will
            create the app for you for free to get you started immediately!</p>
          <p className='font-18 wg-5'>No code to write. Auto Publish. Auto Update.</p>
        </div>
      </section>
    </>
  )
}
