import React from "react";
import { Link } from "react-router-dom";

export default function Faqs(props) {
  const toggleFaq = (id) => {
    let answer = document.querySelector(`#answer-${id}`);
    answer.classList.toggle("open-faq");
  };

  return (
    <section className="faq-section">
      <div className="faq-header">
        <p className="cl-nt-100 wg-6 font-24">FAQ's</p>
        <p className="cl-nt-70 font-14 wg-5">
          Learn more about how we make your life easy!
        </p>
        <p className="cl-nt-70 font-12 wg-4">
          Have more unanswered questions? Feel free to directly
          <Link
            className="wg-6 cl-nt-70"
            style={{ marginLeft: "0.2rem" }}
            to="/about/"
          >
            get in touch with us!
          </Link>
        </p>
      </div>
      <div className="all-faq">
        <div
          className="faq"
          onClick={() => {
            toggleFaq(1);
          }}
        >
          <div className="faq-text">
            <p className="font-12 faq-question">
              Do I need any technical or coding knowledge to create my own
              app/website on NoCodeApp.center?
            </p>
            <span className="answer font-12" id="answer-1">
              <p>Not at all. NoCodeApp is a truly no-code solution.</p>
            </span>
          </div>
          <span className="faq-arrow material-symbols-rounded">
            expand_more
          </span>
        </div>
        <div
          className="faq"
          onClick={() => {
            toggleFaq(2);
          }}
        >
          <div className="faq-text">
            <p className="font-12 faq-question">
              How long would it take to create my own app/website?
            </p>
            <span className="answer font-12" id="answer-2">
              <p>
                You can create your app/website within minutes in 3 simple
                steps.
              </p>
            </span>
          </div>
          <span className="faq-arrow material-symbols-rounded">
            expand_more
          </span>
        </div>
        <div
          className="faq"
          onClick={() => {
            toggleFaq(3);
          }}
        >
          <div className="faq-text">
            <p className="font-12 faq-question">
              Which platforms do my app get published onto? And how long does it
              take?
            </p>
            <span className="answer font-12" id="answer-3">
              <p>
                You can get your apps published on Google Play Store and Apple
                App Store
              </p>
            </span>
          </div>
          <span className="faq-arrow material-symbols-rounded">
            expand_more
          </span>
        </div>
        <div
          className="faq"
          onClick={() => {
            toggleFaq(4);
          }}
        >
          <div className="faq-text">
            <p className="font-12 faq-question">
              Can I get insights on how well my product is performing?
            </p>
            <span className="answer font-12" id="answer-4">
              <p>
                Yes. You get all the analytics built into the platform which
                provides you with insights on how well is your content
                performing.
              </p>
            </span>
          </div>
          <span className="faq-arrow material-symbols-rounded">
            expand_more
          </span>
        </div>
        <div
          className="faq"
          onClick={() => {
            toggleFaq(5);
          }}
        >
          <div className="faq-text">
            <p className="font-12 faq-question">
              Can I invite other people to collaborate and post content on my
              behalf too?
            </p>
            <span className="answer font-12" id="answer-5">
              <p>
                Absolutely. You can invite your team members to collaborate and
                there is a approval mechanism avialbale as well using which you
                can mobitor the content going out.
              </p>
            </span>
          </div>
          <span className="faq-arrow material-symbols-rounded">
            expand_more
          </span>
        </div>
      </div>
    </section>
  );
}
