export const productsData = {
  "mobile-app": {
    title: "Mobile App",
    features: [
      {
        desc: "No code to write",
        icon: require("./assets/images/mob-app-feature-1.png"),
      },
      {
        desc: "We manage apps in Google/Apple App stores",
        icon: require("./assets/images/mob-app-feature-2.png"),
      },
      {
        desc: "Free to start, Pay for only the messages you send",
        icon: require("./assets/images/mob-app-feature-3.png"),
      },
    ],
    allProducts: [
      {
        title: "Build a Mobile App with No Code",
        desc: "Build a feature-rich communication & collaboration app for Android and iOS in just 3 simple steps.",
        image: require("./assets/images/mob-app-build-an-app.png"),
      },
      {
        title: "Publish Posts",
        desc: "Publish interactive content or schedule posts for a later date and time. It's as simple as filling a form.",
        image: require("./assets/images/mob-app-publish-post.png"),
      },
      {
        title: "Business Intelligence",
        desc: "Get detailed insights of your app readers activity, to help you understand how your content performs.",
        image: require("./assets/images/mob-app-business-intel.png"),
      },
      {
        title: "Push Notifications & Real-time Updates",
        desc: "Keep your readers updated with the latest content you publish using push notifications & real-time updates",
        image: require("./assets/images/mob-app-push-notifications.png"),
      },
      {
        title: "Categorization",
        desc: "Sort your content into customized categories for easy filtering by your app reader. You can also select which users can view which categories.",
        image: require("./assets/images/mob-app-categorization.png"),
      },
      {
        title: "Reader Feedback",
        desc: "Take a poll to know what your readers think. Also your readers can contact you right from the application for any feedback or queries.",
        image: require("./assets/images/mob-app-reader-feedback.png"),
      },
      {
        title: "Collaboration",
        desc: "Build a team of collaborators to help you create content for the app.",
        image: require("./assets/images/mob-app-collaboration.png"),
      },
    ],
  },
  "email-newsletter": {
    title: "Email & Newsletter",
    features: [
      {
        desc: "Email AI. Focus on content, not formatting. Send in just a few clicks.",
        icon: require("./assets/images/newsletter-feature-1.png"),
      },
      {
        desc: "Start sending emails today, for free.",
        icon: require("./assets/images/newsletter-feature-2.png"),
      },
      {
        desc: "No monthly fixed cost.",
        icon: require("./assets/images/newsletter-feature-3.png"),
      },
    ],
    allProducts: [
      {
        title: "Various templates to choose from",
        image: require("./assets/images/newsletter-templates.png"),
      },
      {
        title: "Quickly & easily create engaging messages",
        image: require("./assets/images/newsletter-quickly-easily.png"),
      },
      {
        title: "Personalization of content",
        image: require("./assets/images/newsletter-personalization.png"),
      },
      {
        title:
          "Use content you’ve already created to send out newsletters fast",
        image: require("./assets/images/newsletter-use-content.png"),
      },
    ],
  },
  website: {
    title: "Website",
    features: [
      {
        desc: "Keep website updated easily by just creating a message in minutes",
        icon: require("./assets/images/website-feature-1.png"),
      },
      {
        desc: "Integrate the feed into your existing website in minutes",
        icon: require("./assets/images/website-feature-2.png"),
      },
      {
        desc: "Free to start, pay for only the messages posted top the stream.",
        icon: require("./assets/images/website-feature-3.png"),
      },
    ],
    allProducts: [
      {
        title:
          "Social media feed like website which automatically updates when you post",
        image: require("./assets/images/website-social-media.png"),
      },
      {
        title: "Works on any device be it mobile, tablet or desktop",
        image: require("./assets/images/website-works-on.png"),
      },
      {
        title: "Readers get to save favourite posts using bookmarks",
        image: require("./assets/images/website-readers.png"),
      },
      {
        title: "Content categorization",
        image: require("./assets/images/website-categorization.png"),
      },
    ],
  },
};

export const caseStudies = [
  {
    image: require("./assets/images/newsletterpopup.png"),
    title: "Newsletter",
    desc: `Publish the interesting headlines, short description, an image depicting the post and a way for one to quickly access the details, in seconds.
    Schedule your posts to go out when you want.
    Create categories as you publish posts. It will allow one to read content(s) of choice, easily and quickly.
    Assign permission of categories to users. Categories can be free or premium.
    Publish sponsored contents.
    Publish full page Ads with multiple images.
    Get readers feedback instantaneously.
    Users get notifications when you publish stories.
    Send posts in the App, as well as by Email or Text if you like.`,
    whyList: [
      "Engaging and fun to read the content you create.",
      "Unparalleled Business Analytics.",
      "See which posts are most read.",
      "See which users are active.",
      "See which posts are most swiped for details.",
      "See which categories are most popular.",
      "For all of the above scenarios, see detailed analytics.",
      "Analyze location of users, devices they use, & the times they are active.",
      "Engage users through questions and surveys.",
      "Publish sponsored contents and Advertisements for revenue.",
      "Create a brand. Having an App will increase your goodwill.",
    ],
    howList: [
      "Easy to build your app, in minutes. It will be deployed in the Google and Apple App stores right away.",
      "You don’t have time, no worries, just forward us the content you currently create and we will do the work for you.*",
      "Simply add the url to download and install your app in your current distribution model.",
      "You can take your email list or phone numbers, and send an invite to install app from the system.",
      "We will manage your email distribution as well; one place to create your content & publish whichever way you want.",
    ],
  },
  {
    image: require("./assets/images/schoolpopup.png"),
    title: "School",
    desc: `Publish interactive content for students, staff, and parents with an interesting headline, short description, an image depicting the post, and a way for one to quickly access the details, in seconds.
    Schedule your posts to go out when you want.
    Redirect people to Google Sheets or Google Forms for any input or interaction.
    Create categories as you publish stories. It will allow one to read content(s) of choice, easily and quickly.
    Assign permission of categories to users. Categories can be free or premium.
    Publish sponsored content.
    Publish full page Ads with multiple images.
    Get the reader’s feedback instantaneously.
    Users get notifications when you publish posts.
    Get feedback from users on WhatsApp, email, or text for any of the post you put out.`,
    whyList: [
      "Engaging and fun to read the content you create.",
      "Unparalleled Business Analytics.",
      "See which posts are most read, which users are active, which posts are most swiped for details & which categories are most popular.",
      "For all of the above scenarios, see detailed analytics.",
      "Analyze location from where users come, devices they use, and the times they are active.",
      "Engage users through questions and surveys.",
      "Publish sponsored contents and Advertisements for revenue.",
      "Create a brand. Having an App will increase your goodwill.",
    ],
    howList: [
      "Easy to build your app, in minutes. It will be deployed in the Google and Apple App stores right away.",
      "You don’t have time, no worries, just forward us the content you currently create and we will do the work for you.*",
      "Simply add the url to download and install your app in your current distribution model.",
      "You can take your email list or phone numbers, and send an invite to install app from the system.",
    ],
  },
  {
    image: require("./assets/images/eventpopup.png"),
    title: "Event",
    desc: `Publish interactive content for students, staff, and parents with an interesting headline, short description, an image depicting the post, and a way for one to quickly access the details, in seconds.
    Schedule your posts to go out when you want.
    Publish schedules and event changes that go out to all instantaneously.
    Create categories as you publish posts. It will allow one to read content(s) of choice, easily and quickly.
    Assign permission of categories to users. Categories can be free or premium.
    Publish sponsored content.
    Publish full page Ads with multiple images.
    Get the reader’s feedback instantaneously.
    Users get notifications when you publish posts.`,
    whyList: [
      "Engaging and fun to read the content you create.",
      "Unparalleled Business Analytics.",
      "See which posts are most read.",
      "See which users are active.",
      "See which posts are most swiped for details.",
      "See which categories are most popular.",
      "For all of the above scenarios, see detailed analytics.",
      "Analyze location of users, devices they use, & the times they are active.",
      "Engage users through questions and surveys.",
      "Publish sponsored contents and Advertisements for revenue.",
      "Create a brand. Having an App will increase your goodwill.",
    ],
    howList: [
      "Easy to build your app, in minutes. It will be deployed in the Google and Apple App stores right away.",
      "You don’t have time, no worries, just forward us the content you currently create and we will do the work for you.*",
      "Simply add the url to download and install your app in your current distribution model.",
      "You can take your email list or phone numbers, and send an invite to install app from the system.",
    ],
  },
  {
    image: require("./assets/images/catalogpopup.png"),
    title: "Catalog",
    desc: `Publish interactive content for students, staff, and parents with an interesting headline, short description, an image depicting the post, and a way for one to quickly access the details, in seconds.
    Schedule your posts to go out when you want.
    Redirect people to Google Sheets or Google Forms for any input or interaction.
    Create categories as you publish stories. It will allow one to read content(s) of choice, easily and quickly.
    Assign permission of categories to users. Categories can be free or premium.
    Publish sponsored content.
    Publish full page Ads with multiple images.
    Get the reader’s feedback instantaneously.
    Users get notifications when you publish posts.
    Get feedback from users on WhatsApp, email, or text for any of the post you put out.`,
    whyList: [
      "Engaging and fun to read the content you create.",
      "Unparalleled Business Analytics.",
      "See which posts are most read, which users are active, which posts are most swiped for details & which categories are most popular.",
      "For all of the above scenarios, see detailed analytics.",
      "Analyze location from where users come, devices they use, and the times they are active.",
      "Engage users through questions and surveys.",
      "Publish sponsored contents and Advertisements for revenue.",
      "Create a brand. Having an App will increase your goodwill.",
    ],
    howList: [
      "Easy to build your app, in minutes. It will be deployed in the Google and Apple App stores right away.",
      "You don’t have time, no worries, just forward us the content you currently create and we will do the work for you.*",
      "Simply add the url to download and install your app in your current distribution model.",
      "You can take your email list or phone numbers, and send an invite to install app from the system.",
    ],
  },
];

export const plans = [
  {
    planName: "Personal",
    pricing: "Free",
    features: [
      { title: "1000 user reaches", available: true },
      { title: "Business Analytics", available: true },
      {
        title: "Support",
        available: true,
        support: [
          { support_title: "Email", support_available: true },
          { support_title: "Chat", support_available: false },
          { support_title: "Call", support_available: false },
        ],
      },
      { title: "Content Management Service", available: false },
    ],
  },
  {
    planName: "Basic",
    pricing: [99, 999],
    features: [
      { title: "10,000 user reaches", available: true },
      { title: "App launched on iOS and Android platform", available: true },
      { title: "Business Analytics", available: true },
      {
        title: "Support",
        available: true,
        support: [
          { support_title: "Email", support_available: true },
          { support_title: "Chat", support_available: true },
          { support_title: "Call", support_available: false },
        ],
      },
      { title: "Content Management Service", available: false },
    ],
  },
  {
    planName: "Pro",
    pricing: [499, 4999],
    features: [
      { title: "1,000,000 user reaches", available: true },
      { title: "App launched on iOS and Android platform", available: true },
      { title: "Business Analytics", available: true },
      {
        title: "Support",
        available: true,
        support: [
          { support_title: "Email", support_available: true },
          { support_title: "Chat", support_available: true },
          { support_title: "Call", support_available: true },
        ],
      },
      { title: "Content Management Service", available: true },
    ],
  },
  {
    planName: "Enterprise",
    pricing: "Call Us for a customised plan",
    features: [
      { title: "Unlimited user reaches", available: true },
      { title: "App launched on iOS and Android platform", available: true },
      { title: "Business Analytics", available: true },
      {
        title: "Fully Customized Support",
        available: true,
      },
      { title: "Customized Content Management Service", available: true },
    ],
  },
];

export const pricingPlans = {
  INR: {
    Mobile: {
      Title: "App Messages Per Month",
      CostTitle: "Cost Per Message",
      subTitle: "Generic App",
      Price1: "₹0.4", // Next 10,000
      Price2: "₹0.04", // Next 100,000
      Price3: "₹0.036", // Next 400,000
      Price4: "₹0.032", // Next 500,000
      Price5: "₹0.024", // More
      Price6: "₹399", // Subscription per month
      Price7: "₹2,399", // Android or iOS App per month
      Price8: "₹3,999", // Android & iOS Both Apps per month
    },
    Email: {
      Title: "Emails Per Month",
      CostTitle: "Cost Per Email",
      subTitle: "Subscription",
      Price1: "₹0.28", // Next 10,000
      Price2: "₹0.04", // Next 100,000
      Price3: "₹0.024", // Next 400,000
      Price4: "₹0.016", // Next 500,000
      Price5: "₹0.012", // More
      Price6: "₹159", // Subscription per month
      Price7: "₹159", // Every additional 1000 users
    },
    Website: {
      Price1: "₹8.0", // Next 100 posts
      Price2: "₹4.0", // More
      Price3: "₹319", // Subscription per month
    },
    Social: {
      Price1: "₹799", // Subscription per month
      Price2: "₹1,199", // Subscription per month (Unlimited posts)
    },
  },
  USD: {
    Mobile: {
      Title: "App Messages Per Month",
      CostTitle: "Cost Per Message",
      subTitle: "Generic App",
      Price1: "$0.005", // Next 10,000
      Price2: "$0.0005", // Next 100,000
      Price3: "$0.00045", // Next 400,000
      Price4: "$0.0004", // Next 500,000
      Price5: "$0.0003", // More
      Price6: "$4.99", // Subscription per month
      Price7: "$29.99", // Android or iOS App per month
      Price8: "$49.99", // Both Android & iOS Apps per month
    },
    Email: {
      Title: "Emails Per Month",
      CostTitle: "Cost Per Email",
      subTitle: "Subscription",
      Price1: "$0.0035", // Next 10,000
      Price2: "$0.0005", // Next 100,000
      Price3: "$0.0003", // Next 400,000
      Price4: "$0.0002", // Next 500,000
      Price5: "$0.00015", // More
      Price6: "$1.99", // Subscription per month
      Price7: "$1.99", // Every additional 1000 users
    },
    Website: {
      Price1: "$0.10", // Next 100 posts
      Price2: "$0.05", // More
      Price3: "$3.99", // Subscription per month
    },
    Social: {
      Price1: "$9.9", // Subscription per month
      Price2: "$14.99", // Subscription per month (Unlimited posts)
    },
  },
};
