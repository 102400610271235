import { React, useEffect } from 'react'
import Faqs from '../components/Faqs';
import bgCircles from '../assets/images/bg-circles.svg';

export default function Landing(props) {
  useEffect(() => {
    document.title = "NoCodeApp";
    props.toggleNav(true);
  })

  const toggleFaq = (id) => {
    let answer = document.querySelector(`#answer-${id}`);
    answer.classList.toggle("open-faq")
  }

  return (
    <>
      <section className="landing">
        <div className="top-section">
          <div className="landing-taglines">
            <h3 className="font-24 wg-6">
              Connect with your audience. <strong>Better.</strong>
            </h3>
            <p className="font-14 wg-5">Time to evolve to the 21st century</p>
          </div>
          <div className="evolution-img">
            <img src={require("../assets/images/evo-bg.png")} id="evo-bg" alt="" />
            <img src={bgCircles} alt="" />
          </div>
          <div className="devices">
            <img src={require("../assets/images/email.png" )}alt="" />
            <img src={require("../assets/images/flat-iphone.png")} alt="" />
            <img src={require("../assets/images/website.png" )}alt="" />
          </div>
        </div>
        <div className="lower-section">
          <form action="https://my.nocodeapp.center/dashboard" method='GET' style={{display: 'contents'}}>
            <p className="font-14 wg-9">GET STARTED FOR FREE</p>
            <div className="input-fields">
              <input className="input-primary input-primary-white font-12" type="text" placeholder="Your email" name='userEmail'
                pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' />                
              <button className="gs-desktop"><svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" r="28" fill="#CF3560" />
                <path d="M20 27H36" stroke="white" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
                <path d="M28 19L36 27L28 35" stroke="white" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>
              </button>
              <button className="gs-mob btn-primary wg-6 font-14">Sign Up/Log In</button>
            </div>
          </form>
        </div>
      </section>
      <section className="about">
        <div className="about-top">
          <h2 className="font-20">What we do</h2>
          <p className="font-14">
            We help you build apps, and websites, and send SMS, emails and
            newsletters without writing any code.
          </p>
        </div>
        <div className="about-middle">
          <h2 className="font-16">Ever relate to these problems?</h2>
          <div className="problem-list">
            <div className="row">
              <div className="thumbnail">
                <img src={require("../assets/images/pb-ic-1.png")} alt="" />
              </div>
              <p className="description font-12 wg-9">
                Emails are long and not engaging. Click rate is very low because
                of that
              </p>
            </div>
            <div className="row">
              <div className="thumbnail">
                <img src={require("../assets/images/pb-ic-2.png")} alt="" />
              </div>
              <p className="description font-12 wg-9">
                Websites rarely get updated once set up
              </p>
            </div>
            <div className="row">
              <div className="thumbnail">
                <img src={require("../assets/images/pb-ic-3.png")} alt="" />
              </div>
              <p className="description font-12 wg-9">
                Curating content for newsletter is time consuming
              </p>
            </div>
            <div className="row">
              <div className="thumbnail">
                <img src={require("../assets/images/pb-ic-4.png")} alt="" />
              </div>
              <p className="description font-12 wg-9">
                Developing and managing app is technically challenging and
                expensive
              </p>
            </div>
            <div className="row">
              <div className="thumbnail">
                <img src={require("../assets/images/pb-ic-5.png")} alt="" />
              </div>
              <p className="description font-12 wg-9">
                Inconsistent messaging across web, email, newsletter & social
                media.
              </p>
            </div>
          </div>
        </div>
        <div className="about-bottom">
          <h2 className="font-16">Here's how we do it better!</h2>
          <div className="solution-list">
            <div className="row">
              <div className="sol-ic">
                <img src={require("../assets/images/sl-ic-1.png")} alt="" />
              </div>
              <div className="sol-desc">
                <h3 className="sol-title font-16 wg-6">Convenient</h3>
                <p className="sol-desc font-12">
                  You don’t need -any- technical knowledge or coding skills to
                  create products on our platform
                </p>
                {/* <a href="" className="sol-link font-14">Learn more</a> */}
              </div>
            </div>
            <div className="row">
              <div className="sol-ic">
                <img src={require("../assets/images/sl-ic-2.png")} alt="" />
              </div>
              <div className="sol-desc">
                <h3 className="sol-title font-16 wg-6">Engaging</h3>
                <p className="sol-desc font-12">
                  We believe there’s a far more interesting way to connect with
                  your audience & make it look incredible!
                </p>
                {/* <a href="" className="sol-link font-14">Learn more</a> */}
              </div>
            </div>
            <div className="row">
              <div className="sol-ic">
                <img src={require("../assets/images/sl-ic-3.png")} alt="" />
              </div>
              <div className="sol-desc">
                <h3 className="sol-title font-16 wg-6">Cost-effective</h3>
                <p className="sol-desc font-12">
                  Forget about unnecessary charges for features you never end up
                  using. With us, you -only- pay for what you use!
                </p>
                {/* <a href="" className="sol-link font-14">Learn more</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services services-mobile">
        <div className="service-info-card" data-nth="odd">
          <p className="title font-16 wg-6">Mobile App</p>
          <p className="desc font-12 wg-6">
            Convey your message in an engaging way and enhance your brand by
            creating your own Apple and Android Apps, in minutes, with no code
            to write.
          </p>
          <img src={require("../assets/images/ser-ic-1.png")} alt="" />
          <a href="/product/mobile-app" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
            <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
          </a>
        </div>
        <div className="service-info-card" data-nth="even">
          <p className="title font-16 wg-6">Email</p>
          <p className="desc font-12 wg-6">
            Send nicely formatted email messages that get better engagement.
          </p>
          <img src={require("../assets/images/ser-ic-2.png")} alt="" />
          <a href="/product/email-newsletter" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
            <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
          </a>
        </div>
        <div className="service-info-card" data-nth="odd">
          <p className="title font-16 wg-6">Newsletter</p>
          <p className="desc font-12 wg-6">
            Send well-crafted customized newsletters that your audience will
            want to read
          </p>
          <img src={require("../assets/images/ser-ic-3.png")} alt="" />
          <a href="/product/email-newsletter" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
            <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
          </a>
        </div>
        <div className="service-info-card" data-nth="even">
          <p className="title font-16 wg-6">Website</p>
          <p className="desc font-12 wg-6">
            Get an automatically updated website with no extra effort
          </p>
          <img src={require("../assets/images/ser-ic-4.png")} alt="" />
          <a href="/product/website" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
            <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
          </a>
        </div>
        <div className="service-info-card" data-nth="odd">
          <p className="title font-16 wg-6">Social Media</p>
          <p className="desc font-12 wg-6">
            Manage all your social media content from one place
          </p>
          <img src={require("../assets/images/ser-ic-5.png")} alt="" />
          <p className="link disabled font-14 wg-6">Coming Soon</p>
        </div>
        <div className="service-info-card" data-nth="even">
          <p className="title font-16 wg-6">SMS</p>
          <p className="desc font-12 wg-6">
            Reach your audience via SMS with a single click
          </p>
          <img src={require("../assets/images/ser-ic-6.png")} alt="" />
          <p className="link disabled font-14 wg-6">Coming Soon</p>
        </div>
        <div className="service-info-card" data-nth="odd">
          <p className="title font-16 wg-6">WhatsApp</p>
          <p className="desc font-12 wg-6">
            Connect with your audience using WhatsApp seamlessly
          </p>
          <img src={require("../assets/images/ser-ic-7.png")} alt="" />
          <p className="link disabled font-14 wg-6">Coming Soon</p>
        </div>
      </section>
      <section className="services services-desktop">
        <div className="service-info-card" data-nth="odd">
          <div className="service-text">
            <p className="title font-16 wg-6">Mobile App</p>
            <p className="desc font-12 wg-6">
              Convey your message in an engaging way and enhance your brand by
              creating your own Apple and Android Apps, in minutes, with no code
              to write.
            </p>
            <a href="/product/mobile-app" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
              <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            </a>
          </div>
          <img src={require("../assets/images/ser-ic-1.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="even">
          <div className="service-text">
            <p className="title font-16 wg-6">Email</p>
            <p className="desc font-12 wg-6">
              Send nicely formatted email messages that get better engagement.
            </p>
            <a href="/product/email-newsletter" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
              <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            </a>
          </div>
          <img src={require("../assets/images/ser-ic-2.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="odd">
          <div className="service-text">
            <p className="title font-16 wg-6">Newsletter</p>
            <p className="desc font-12 wg-6">
              Send well-crafted customized newsletters that your audience will
              want to read
            </p>
            <a href="/product/email-newsletter" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
              <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            </a>
          </div>
          <img src={require("../assets/images/ser-ic-3.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="even">
          <div className="service-text">
            <p className="title font-16 wg-6">Website</p>
            <p className="desc font-12 wg-6">
              Get an automatically updated website with no extra effort
            </p>
            <a href="/product/website" className="link font-14 wg-6">Explore how<svg width="14" height="14" viewBox="0 0 14 14"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7H13" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
              <path d="M7 13L13 7L7 1" stroke="#48484A" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            </a>
          </div>
          <img src={require("../assets/images/ser-ic-4.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="odd">
          <div className="service-text">
            <p className="title font-16 wg-6">Social Media</p>
            <p className="desc font-12 wg-6">
              Manage all your social media content from one place
            </p>
            <p className="link disabled font-14 wg-6">Coming Soon</p>
          </div>
          <img src={require("../assets/images/ser-ic-5.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="even">
          <div className="service-text">
            <p className="title font-16 wg-6">SMS</p>
            <p className="desc font-12 wg-6">
              Reach your audience via SMS with a single click
            </p>
            <p className="link disabled font-14 wg-6">Coming Soon</p>
          </div>
          <img src={require("../assets/images/ser-ic-6.png")} alt="" />
        </div>
        <div className="service-info-card" data-nth="odd">
          <div className="service-text">
            <p className="title font-16 wg-6">WhatsApp</p>
            <p className="desc font-12 wg-6">
              Connect with your audience using WhatsApp seamlessly
            </p>
            <p className="link disabled font-14 wg-6">Coming Soon</p>
          </div>
          <img src={require("../assets/images/ser-ic-7.png")} alt="" />
        </div>
      </section>
      
      <Faqs />
    </>
  )
}
