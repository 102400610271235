import { React, useState, useRef, useEffect } from "react";

export default function PrivacyPolicy(props) {

    useEffect(() => {
        document.title = "PrivacyPolicy | NoCodeApp";
        props.setNav(false);
    });

    return (
        <section id="privacypolicy" className="container">
            <h1 className="font-30 policy-main-title">Privacy Policy</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    Our Privacy Policy helps explain our information practices.
                    When we say “NoCodeApp,” “our,” “we,” or “us,” we’re talking about NoCodeApp Inc. This
                    Privacy Policy (“Privacy Policy”) applies to all of our apps, services, features, software, and
                    website (together, “Services”) unless specified otherwise.
                    Our Privacy Policy describes what information we collect and how this affects you, that includes
                    users who build apps and products using our Services, as well as the users who use those apps
                    and products, and in turn use our Services.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Information We Collect</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    NoCodeApp receives or collects information when we operate and provide our Services,
                    including when you install, access, or use our Services.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Information You Provide</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    You provide your name, email and mobile phone number and other optional information such as
                    company, your areas of interest etc, to create an account.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Automatically Collected Information</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    Usage and Log Information. We collect service-related, diagnostic, and performance
                    information. This includes information but not limited to your activity, log files, and diagnostic,
                    crash, website, and performance logs and reports.
                </p>

                <p className="description font-16 policy-description">
                    Device and Connection Information. We collect device-specific information when you install,
                    access, or use our Services. This includes information such as hardware model, operating
                    system information, browser information, IP address, mobile network information including
                    phone number, and device identifiers. We also collect device location information.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">How We Use Information</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    We use all the information we have to help us operate, provide, improve, understand,
                    customize, support, and market our Services.
                </p>

                <p className="description font-16 policy-description">
                    Our Services. We operate and provide our Services, including providing customer support, and
                    improving, fixing, and customizing our Services. We understand how people use our Services,
                    and analyze and use the information we have to evaluate and improve our Services, research,
                    develop, and test new services and features, and conduct troubleshooting activities. We also
                    use your information to respond to you when you contact us. We use cookies to operate,
                    provide, improve, understand, and customize our Services.
                </p>

                <p className="description font-16 policy-description">
                    Safety and Security. We verify accounts and activity, and promote safety and security on and off
                    our Services, such as by investigating suspicious activity or violations of our Terms, and to
                    ensure our Services are being used legally.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Information You And We Share</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    You share your information as you use and communicate through our Services, and we share
                    your information to help us operate, provide, improve, understand, customize, support, and
                    market our Services.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Assignment, Change Of Control, And Transfer</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    All of our rights and obligations under our Privacy Policy are freely assignable by us to any of
                    our affiliates, in connection with a merger, acquisition, restructuring, or sale of assets, or by
                    operation of law or otherwise, and we may transfer your information to any of our affiliates,
                    successor entities, or new owner.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Law And Protection</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    We may collect, use, preserve, and share your information if we have a good-faith belief that it
                    is reasonably necessary to: (a) respond pursuant to applicable law or regulations, to legal
                    process, or to government requests; (b) enforce our Terms and any other applicable terms and
                    policies, including for investigations of potential violations; (c) detect, investigate, prevent, and
                    address fraud and other illegal activity, security, or technical issues; or (d) protect the rights,
                    property, and safety of our users, NoCodeApp, or others.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Our Global Operations</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    You agree to our information practices, including the collection, use, processing, and sharing of
                    your information as described in this Privacy Policy, as well as the transfer and processing of
                    your information to the United States and other countries globally where we have or use
                    facilities, service providers, or partners, regardless of where you use our Services. You
                    acknowledge that the laws, regulations, and standards of the country in which your information
                    is stored or processed may be different from those of your own country.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Updates To Our Policy</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    We may amend or update our Privacy Policy. We will provide you notice of amendments to this
                    Privacy Policy, as appropriate, and update the “Last Modified” date at the top of this Privacy
                    Policy. Your continued use of our Services confirms your acceptance of our Privacy Policy, as
                    amended. If you do not agree to our Privacy Policy, as amended, you must stop using our
                    Services. Please review our Privacy Policy from time to time.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">App and Content Ownership</h1>
            <div className="custom-container">
                <p className="description font-16 policy-description">
                    App Creator is fully responsible for all it’s content. The app, it’s name and its content would be
                    owned by the app account owner. For the avoidance of any doubt, for an individual account, all
                    apps and their rights are owned by the individual who owns the account, and for an organization
                    account, all apps and their rights are owned by the organization that owns the account. It's an
                    organization's responsibility and rights to decide if an app creator under their account has joint
                    or any rights to the apps and the contents created within those apps. Rights to any content
                    published within any app by us would be owned by us.
                </p>
            </div>


            <h1 className="font-30 policy-main-title">Contact Us</h1>
            <div className="custom-container">
                <p className="description font-16">
                    If you have questions about our Privacy Policy, please contact us.
                </p>

                <p className="description font-16">
                    NoCodeApp Center LLC.
                </p>

                <p className="description font-16">
                    6 Sire stakes Dr
                </p>

                <p className="description font-16">
                    Tinton Falls, NJ 07724
                </p>

                <p className="description font-16">
                    +1-646-688-7500
                </p>
            </div>
        </section>
    )
}